<template>
  <div class="pricing-page">
    <div class="desktop">
      <desktopPage />
    </div>
  </div>
</template>

<script>
import { MobileDetect } from '../../../md'
import desktopPage from './desktopPage.vue'
import mobilePage from './mobilePage.vue'
export default {
  data() {
    return {
      isMobile: !!MobileDetect.mobile(),
    }
  },

  components: {
    desktopPage,
    mobilePage,
  },
}
</script>

<style lang="scss" src="./styles/styles.scss"></style>
