import MD from 'mobile-detect'
// const MD = require('mobile-detect')

// console.log( md.mobile() );          // 'Sony'
// console.log( md.phone() );           // 'Sony'
// console.log( md.tablet() );          // null
// console.log( md.userAgent() );       // 'Safari'
// console.log( md.os() );              // 'AndroidOS'
// console.log( md.is('iPhone') );      // false
// console.log( md.is('bot') );         // false
// console.log( md.version('Webkit') );         // 534.3
// console.log( md.versionStr('Build') );       // '4.1.A.0.562'
// console.log( md.match('playstation|xbox') ); // false

export const MobileDetect = new MD(window.navigator.userAgent);