<template>
  <div class="desktop-page-component">
    <div class="pricing container-fluid">
      <div class="container">
        <div class="pricing__box">
          <div class="pricing__title w-100 text-center">
            <span>{{ $t('pricing_page.title') }}</span>
          </div>
          <div class="pricing__info w-100 text-center">
            <div class="pricing__description">
              <p>
                <span>{{ $t('pricing_page.became') }}</span>
                {{ $t('pricing_page.join') }}
              </p>
            </div>
            <div class="pricing__description">
              <p>
                {{ $t('pricing_page.smart') }}
                <span>{{ $t('pricing_page.free') }}</span
                >, {{ $t('pricing_page.create_account') }}
              </p>
            </div>
          </div>
          <div class="container w-100">
            <!-- <div class="container"> -->
            <div
              class="row  align-items-center justify-content-center w-100 price__boxes"
            >
              <PricingCard
                v-for="(price, i) in pricing"
                :key="i"
                :price="price"
              />
            </div>
          </div>
          <!-- <div class="pricing__button">
            <router-link to="user/account">
              <button class="try">{{ $t('home_page.try_free') }}</button>
            </router-link>
          </div> -->
        </div>
      </div>
    </div>
    <div class="pricing-table container-fluid pb-5"></div>
    <div class="smart-trading container-fluid">
      <div class="container">
        <div class="smart-trading__box">
          <div class="pre-title">
            <span>{{ $t('pricing_page.free_to_use') }}</span>
          </div>
          <div class="title">
            <span>{{ $t('pricing_page.start_trade') }}</span>
          </div>
          <div class="description">
            <p>
              {{ $t('pricing_page.description') }}
            </p>
          </div>
          <div class="start-button">
            <router-link to="user/account">
              <button>{{ $t('pricing_page.start') }}</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="cards container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 cards_accept">
            <div class="cards__title">
              <span>{{ $t('pricing_page.accept') }}</span>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 cards__list">
            <div class="skrill">
              <img src="~@assets/img/skrill.png" alt="skrill" />
            </div>
            <div class="visa">
              <img src="~@assets/img/visa.png" alt="visa" />
            </div>
            <div class="mc">
              <img src="~@assets/img/mc.png" alt="master_card" />
            </div>
            <div class="neteller">
              <img src="~@assets/img/neteller.png" alt="neteller" />
            </div>
            <div class="wire">
              <img src="~@assets/img/wire.png" alt="wire" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { MobileDetect } from '../../../md'
import PricingCard from './card/card.vue'
import http from '../../../http'
export default {
  components: {
    PricingCard,
  },
  data() {
    return {
      pricing: null,
    }
  },
  computed: {
    ...mapState('User', ['me']),
  },
  methods: {
    ...mapActions('Pricing', ['getPricing']),
    initData() {
      this.getPricing().then(({ data }) => {
        console.log(data)
        this.pricing = data
      })
    },
  },
  mounted() {
    if (!http.getToken) this.$router.push({ name: 'sign-in' })
    this.initData()
  },
}
</script>

<style lang="scss" src="./styles/desktopStyle.scss"></style>
